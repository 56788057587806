<template>
  <div class="tab-content" id="PTtab-7">
    <ul class="tablist">
      <li class="tabmenu" data-tab="moneytab_1" @click="currentTab = 'moneytab_1'" :class="{'current': currentTab == 'moneytab_1'}">하부머니 지급/회수</li>
      <li class="tabmenu" data-tab="moneytab_2" @click="currentTab = 'moneytab_2'" :class="{'current': currentTab == 'moneytab_2'}">하부머니 지급/회수 내역</li>
    </ul>
    <div id="moneytab_1" :key="'moneytab_1'" class="tab-content" v-if="currentTab == 'moneytab_1'">
      <!--div class="pagenamPT">
        <h3 class="pagename2">{{$t('front.give.title1')}}</h3>
      </div-->
      <div id="userMoney">
        <ul>
          <li>
            <select v-model="selectPartnerCode">
              <option value="" disabled selected>{{$t('front.give.bottomPatner')}}</option>
              <template v-for="item in partnerLevelList" v-bind:key="item.code">
                <option :value="item.code">{{item.codeName}}</option>
              </template>
            </select>
          </li>
          <li>
            <select v-model="selectMem" style="width: 200px">
              <option value="" disabled selected>{{$t('front.give.resultIDs')}}</option>
              <template v-for="member in myMemIdsList" v-bind:key="member.memId">
                <option :value="member">{{member.memId}}({{member.memNick}})</option>
              </template>
            </select>
          </li>
          <li class="flexnowrap">{{$t('front.give.moneys')}}
            <input type="text" :placeholder="$t('front.give.moneyInput')" :value="formattedCashAmt" @input="cashAmt = parseFormattedValue($event.target.value)" />
            <input type="text" :placeholder="$t('front.give.targetMoney')" v-model="selectMem.cashAmt" readonly />
          </li>
          <li>
            <a class="bgb" @click="memCash('-3')">{{$t('front.give.give')}}</a>
            <a class="bgr" @click="memCash('3')">{{$t('front.give.back')}}</a>
          </li>
        </ul>
      </div>
      <div class="pagenamPT">
        <h3 class="pagename2">현재 접속중인 회원</h3>
      </div>
      <div>
        <div class="strTablescr">
          <div class="strTablePC">
              <table class="strTablePT">
                <colgroup>
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                </colgroup>
                <thead>
                <tr>
<!--                  <th>IDX</th>-->
                  <th>아이디</th>
                  <th>닉네임</th>
                  <th>보유금</th>
                  <th>로그인 시간</th>
                  <th>로그인 횟수</th>
                </tr>
                </thead>
                <tbody>
                  <template v-if="loginMemList.length > 0">
                    <template v-for="(item) in loginMemList" :key="item.memId">
                      <tr>
<!--                        <td>23</td>-->
                        <td>{{item.memId}}</td>
                        <td>{{item.memNick}}</td>
                        <td>{{thousand(item.cashAmt)}}</td>
                        <td>{{dateFormatAll(item.loginDt)}}</td>
                        <td>{{thousand(item.totalLoginCount)}}</td>
<!--                        <td>-->
<!--                          <a class="bgb btn">{{$t('front.give.give')}}</a>-->
<!--                          <a class="bgr btn">{{$t('front.give.back')}}</a>-->
<!--                        </td>-->
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="6">현재 접속 중인 회원이 없습니다.</td>
                    </tr>
                  </template>
                </tbody>
              </table>
          </div>
          <div class="strTableM">
              <div class="strTablePTM">
                <template v-if="loginMemList.length > 0">
                    <template v-for="(item) in loginMemList" v-bind:key="item.memId">
                      <ul>
                        <li><em>아이디</em>{{item.memId}}</li>
                        <li><em>닉네임</em>{{item.memNick}}</li>
                        <li><em>보유</em>{{thousand(item.cashAmt)}}</li>
                        <li><em>로그인 시간</em>{{dateFormatAll(item.loginDt)}}</li>
                        <li><em>로그인 횟수</em>{{thousand(item.totalLoginCount)}}</li>
                      </ul>
                    </template>
                  </template>
                  <template v-else>
                  <ul>
                      <li>내역 없음</li>
                  </ul>
                </template>
              </div>
          </div>
        </div>

        <pagination v-if="pageInfo"
                    :pageNum="pageInfo.page"
                    :pageSize="pageInfo.count_per_list"
                    :totalCount="pageInfo.tatal_list_count"
                    :className="'partnerPaging'"
                    @goToPage="loadData"
        />
      </div>
    </div>
    <div id="moneytab_2" :key="'moneytab_2'" class="tab-content" v-if="currentTab == 'moneytab_2'">
      <!--div class="pagenamew">
        <h3 class="pagename2">{{$t('front.give.title2')}}</h3>
      </div-->
      <div class="datesearchPT">
        <p>{{$t('front.give.date')}}:
          <input type="date" :value="searchForm.startDate" @change="onChangeStartDate"/>
          <span>~</span>
          <input type="date" :value="searchForm.endDate" @change="onChangeEndDate"/>
        </p>
        <ul>
          <li>
            받은 아이디: <input type="text" v-model="searchForm.botMemId"/>
          </li>
          <li>
            성공처리여부:
            <select v-model="searchForm.cashStatus">
              <option :value="''">{{$t('front.gameCategory.all')}}</option>
              <option :value="'1'">{{$t('front.give.ok')}}</option>
              <option :value="'-1'">{{$t('front.give.fail')}}</option>
            </select>
          </li>
          <li>
            종류:
            <select v-model="searchForm.cashType">
              <option :value="''">{{$t('front.gameCategory.all')}}</option>
              <option :value="'-3'">{{$t('front.give.give')}}</option>
              <option :value="'3'">{{$t('front.give.back')}}</option>
            </select>
          </li>
          <li>
            <a @click="loadData(1)">
              <img src="@/assets/img/search.png" alt=""/>
            </a>
          </li>
        </ul>
      </div>
      <div class="datesearchM moveM">
        <ul>
          <li>
            <em>{{$t('front.give.date')}}</em>:
            <input type="date" :value="searchForm.startDate" @change="onChangeStartDate"/>
            <span>~</span>
            <input type="date" :value="searchForm.endDate" @change="onChangeEndDate"/>
          </li>
          <li>
            <em>받은 아이디</em>: <input type="text" v-model="searchForm.botMemId"/>
          </li>
          <li>
            <em>성공처리여부</em>:
            <select v-model="searchForm.cashStatus">
              <option :value="''">{{$t('front.gameCategory.all')}}</option>
              <option :value="'1'">{{$t('front.give.ok')}}</option>
              <option :value="'-1'">{{$t('front.give.fail')}}</option>
            </select>
          </li>
          <li>
            <em>종류</em>:
            <select v-model="searchForm.cashType">
              <option :value="''">{{$t('front.gameCategory.all')}}</option>
              <option :value="'-3'">{{$t('front.give.give')}}</option>
              <option :value="'3'">{{$t('front.give.back')}}</option>
            </select>
          </li>
          <li class="PTsch">
            <a @click="loadData(1)">
              <img src="@/assets/img/search.png" alt=""/>
            </a>
          </li>
        </ul>
      </div>
      <div>
        <div class="strTablescr">
          <div class="strTablePC">
              <table class="strTablePT">
                <colgroup>
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                </colgroup>
                <thead>
                <tr>
                  <th>IDX</th>
                  <th>받은아이디</th>
                  <th>닉네임</th>
                  <th>내용</th>
                  <th>전송금액</th>
                  <th>처리 후 캐시</th>
                  <th>내 보유 캐시</th>
                  <th>전송일시</th>
                  <th>처리 여부</th>
                  <th>비고</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="list.length > 0">
                  <template v-for="(item, index) in list" v-bind:key="item.cashIdx">
                    <tr>
                      <td>{{((pageInfo.page-1) * 10) +  (index + 1)}}</td>
                      <td>{{item.botMemId}}</td>
                      <td>{{item.botMemNick}}</td>
                      <td class="blc" v-if="item.cashType === '-3'">{{$t('front.give.give')}}</td>
                      <td class="rdc" v-else>회수</td>
                      <td :class="{'rdc': item.cashType ==='3', 'blc':item.cashType ==='-3'}">{{thousand(item.cashAmt)}}</td>
                      <td>{{thousand(item.aftCashAmt)}}</td>
                      <td>{{thousand(item.preCashAmt)}}</td>
                      <td>{{dateFormatAll(item.regDate)}}</td>
                      <td class="blc" v-if="item.cashStatus === '1'">O</td>
                      <td class="rdc" v-else>X</td>
                      <td>{{item.memo}}</td>
                    </tr>
                  </template>
                </template>

                </tbody>
              </table>
          </div>
          <div class="strTableM">
              <div class="strTablePTM">
                <template v-if="list.length > 0">
                    <template v-for="(item, index) in list" v-bind:key="item.cashIdx">
                      <ul>
                        <li><em>IDX</em>{{((pageInfo.page-1) * 10) +  (index + 1)}}</li>
                        <li><em>받은아이디</em>{{item.botMemId}}</li>
                        <li><em>닉네임</em>{{item.botMemNick}}</li>
                        <li class="blc" v-if="item.cashType === '-3'"><em>내용</em>{{$t('front.give.give')}}</li>
                        <li class="rdc" v-else><em>내용</em>회수</li>
                        <li :class="{'rdc': item.cashType ==='3', 'blc':item.cashType ==='-3'}"><em>전송금액</em>{{thousand(item.cashAmt)}}</li>
                        <li><em>처리 후 캐시</em>{{thousand(item.aftCashAmt)}}</li>
                        <li><em>내 보유 캐시</em>{{thousand(item.preCashAmt)}}</li>
                        <li><em>전송일시</em>{{dateFormatAll(item.regDate)}}</li>
                        <li class="blc" v-if="item.cashStatus === '1'"><em>처리 여부</em>O</li>
                        <li class="rdc" v-else><em>처리 여부</em>X</li>
                        <li><em>비고</em>{{item.memo}}</li>
                      </ul>
                    </template>
                  </template>
                  <template v-else>
                  <ul>
                      <li>내역 없음</li>
                  </ul>
                </template>
              </div>
          </div>
        </div>

        <pagination v-if="pageInfo && currentTab === 'moneytab_2'"
                    :pageNum="pageInfo.page"
                    :pageSize="pageInfo.count_per_list"
                    :totalCount="pageInfo.tatal_list_count"
                    :className="'partnerPaging'"
                    @goToPage="loadData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination.vue'
import { getCashSendList, memCashInOut } from '@/api/give'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'
import { getRetailMyLoginMem, partnerLevels, partnerMyMemIds } from '@/api/retail'
import { mapState } from 'vuex'

export default {
  name: 'PartnerMoneyMove',
  components: { Pagination },
  data () {
    return {
      currentTab: 'moneytab_1',
      model: {},
      selectMemId: '',
      selectMem: {
        cashAmt: ''
      },
      cashAmt: '',
      list: [],
      searchForm: {
        cashType: '',
        cashStatus: '',
        botMemId: '',
        startDate: getDateStr(getSubDaysDate(new Date(), 7)),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd')
      },
      partnerLevelList: [],
      selectPartnerCode: '',
      myMemIdsList: [],
      isProcessing: false,
      loginMemList: []
    }
  },
  watch: {
    selectPartnerCode () {
      if (this.selectPartnerCode) {
        this.selectMem.cashAmt = ''
        this.getMyMemIds()
      }
    },
    selectMem () {
      if (this.selectMem) {
        this.selectMem.cashAmt = thousand(this.selectMem.cashAmt)
      }
    }
  },
  computed: {
    ...mapState([
      'userData'
    ]),
    formattedCashAmt () {
      if (!this.cashAmt) return ''
      return this.cashAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  async created () {
    this.getPartnerLevels()
    await this.getLoginMember()
    this.loadData()
  },
  methods: {
    thousand,
    async getLoginMember () {
      const res = await getRetailMyLoginMem()
      console.log(res)
      const result = res.data
      if (result.resultCode === '0') {
        this.loginMemList = result.data.loginMemList
      }
    },
    parseFormattedValue (value) {
      return value.replace(/,/g, '')
    },
    getMyMemIds () {
      const params = {
        code: this.selectPartnerCode,
        masterCode: 'partner'
      }
      partnerMyMemIds(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.myMemIdsList = result.data.memIds
        }
      })
    },
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
        }
      })
    },
    onChangeStartDate (event) {
      const startDate = event.target.value
      this.searchForm.startDate = startDate
    },
    onChangeEndDate (event) {
      const endDate = event.target.value
      this.searchForm.endDate = endDate
    },
    loadData (page) {
      if (!page) page = this.pageInfo.page
      const form = JSON.parse(JSON.stringify(this.searchForm))
      form.startDate += ' 00:00:00'
      form.endDate += ' 23:59:59'
      // form.page = page

      const params = {
        ...form,
        page: page
      }
      this.emitter.emit('Loading', true)
      getCashSendList(params).then(response => {
        this.emitter.emit('Loading', false)
        const result = response.data
        if (result.resultCode === '0') {
          this.list = result.data.list
          if (result.data.pageInfo) {
            this.pageInfo = result.data.pageInfo
          }
        }
      })
    },
    async memCash (type) {
      if (!this.cashAmt) {
        await this.onAlert('warningart', 'front.give.moneyInput')
        return false
      }

      if (!this.selectMem.memId) {
        await this.onAlert('warningart', '회원 아이디를 선택해주세요.')
        return false
      }

      if (!this.isProcessing) {
        this.isProcessing = true

        const params = {
          memId: this.selectMem.memId
        }
        if (type === '-3') {
          params.inAmt = this.cashAmt
          if (Number(this.cashAmt) > Number(this.userData.cashAmt)) {
            await this.onAlert('warningart', '보유금보다 지급 금액이 큽니다.')
            this.isProcessing = false
            return false
          }
        } else {
          params.outAmt = this.cashAmt
          if (Number(this.cashAmt) > Number(this.selectMem.cashAmt)) {
            await this.onAlert('warningart', '보유금보다 회수 금액이 큽니다.')
            this.isProcessing = false
            return false
          }
        }

        const message = this.$t('front.cash.moneyMoveConfirm', { memNick: this.selectMem.memNick, cashAmt: thousand(this.cashAmt), type: type === '-3' ? '지급' : '회수' })

        const confirm = await this.onConfirm(message)
        if (confirm) {
          this.emitter.emit('Loading', true)
          memCashInOut(params, type).then(async response => {
            const result = response.data
            this.emitter.emit('Loading', false)
            if (result.resultCode === '0') {
              if (type === '-3') {
                this.onCheck('지급 완료')
              } else {
                this.onCheck('회수 완료')
              }

              this.selectMem = {}
              this.selectMem.cashAmt = ''
              this.cashAmt = ''
              this.loadData()
            } else {
              await this.onAlert('warningart', result.resultMessage)
            }
            this.isProcessing = false
          })
        } else {
          this.isProcessing = false
        }
      }
    }
  }
}
</script>
<style scoped>
  .datesearchPT input[type="date"]::-webkit-calendar-picker-indicator{background: url(../../../assets/img/PTcalender.svg);display: block;background-repeat: no-repeat;background-size: contain;}
</style>
<style scoped src="@/styles/striNew.css"></style>
